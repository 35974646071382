define("ember-power-select/components/power-select/placeholder", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @placeholder}}
    <span class="ember-power-select-placeholder" ...attributes>{{@placeholder}}</span>
  {{/if}}
  
  */
  {
    "id": "7iOUtQCZ",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,1],[24,0,\"ember-power-select-placeholder\"],[17,2],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null]],[\"@placeholder\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "ember-power-select/components/power-select/placeholder.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});