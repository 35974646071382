define("@adopted-ember-addons/ember-stripe-elements/utils/stripe-mock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stripeEventUtils = _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var StripeMock = function StripeMock(publishableKey) {
    this.publishableKey = publishableKey;
  };
  StripeMock.prototype.elements = function () {
    return {
      create: function create() {
        return {
          mount: function mount() {},
          on: function on() {},
          unmount: function unmount() {}
        };
      }
    };
  };
  StripeMock.prototype.confirmCardPayment = function () {};
  StripeMock.prototype.createToken = function () {};
  StripeMock.prototype.createSource = function () {};
  StripeMock.prototype.createPaymentMethod = function () {};
  StripeMock.prototype.retrieveSource = function () {};
  StripeMock.prototype.paymentRequest = function () {};
  StripeMock.prototype.redirectToCheckout = function () {};
  StripeMock.prototype.retrievePaymentIntent = function () {};
  StripeMock.prototype.handleCardPayment = function () {};
  StripeMock.prototype.handleCardAction = function () {};
  StripeMock.prototype.confirmPaymentIntent = function () {};
  StripeMock.prototype.handleCardSetup = function () {};
  StripeMock.prototype.confirmCardSetup = function () {};
  StripeMock.prototype.retrieveSetupIntent = function () {};
  StripeMock.prototype.confirmSetupIntent = function () {};
  var cardArgs = {
    elementType: "card"
  };
  var baseArgs = _objectSpread(_objectSpread({}, cardArgs), {}, {
    "error": undefined,
    "value": {
      "postalCode": ""
    },
    "empty": true,
    "complete": false,
    "brand": "unknown"
  });
  var stripeError = {
    message: "Your card number is invalid.",
    type: "validation_error",
    code: "invalid_number"
  };
  var argsError = _objectSpread(_objectSpread({}, baseArgs), {}, {
    error: stripeError,
    "brand": "visa",
    "value": {
      "postalCode": "12345"
    }
  });
  var argsComplete = _objectSpread(_objectSpread({}, baseArgs), {}, {
    "complete": true
  });
  var stripeEventUtils = _exports.stripeEventUtils = {
    triggerReady: function triggerReady(stripeElement) {
      stripeElement._emitEvent('ready'), cardArgs;
    },
    triggerBlur: function triggerBlur(stripeElement) {
      stripeElement._emitEvent('blur', cardArgs);
    },
    triggerFocus: function triggerFocus(stripeElement) {
      stripeElement._emitEvent('focus', cardArgs);
    },
    triggerIncomplete: function triggerIncomplete(stripeElement) {
      stripeElement._emitEvent('change', baseArgs);
    },
    triggerError: function triggerError(stripeElement) {
      var userArgs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      stripeElement._emitEvent('change', _objectSpread(_objectSpread({}, argsError), userArgs));
    },
    triggerComplete: function triggerComplete(stripeElement) {
      stripeElement._emitEvent('change', argsComplete);
    },
    triggerChange: function triggerChange(stripeElement) {
      var userArgs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      stripeElement._emitEvent('change', _objectSpread(_objectSpread({}, baseArgs), userArgs));
    }
  };
  var _default = _exports.default = StripeMock;
});