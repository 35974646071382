define("ember-power-datepicker/utils/computed-properties", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fallbackAction = fallbackAction;
  function fallbackAction(fallback) {
    return (0, _object.computed)({
      get: function get() {
        return fallback.bind(this);
      },
      set: function set(_, v) {
        return v === undefined ? fallback.bind(this) : v;
      }
    });
  }
});