define("ember-bootstrap-changeset-validations/components/bs-form", ["exports", "@ember/object/computed", "@ember/debug", "rsvp", "ember-bootstrap/components/bs-form"], function (_exports, _computed, _debug, _rsvp, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _bsForm.default.extend({
    '__ember-bootstrap_subclass': true,
    hasValidator: (0, _computed.notEmpty)('model.validate'),
    validate: function validate(model) {
      var m = model;
      (false && !(m && typeof m.validate === 'function') && (0, _debug.assert)('Model must be a Changeset instance', m && typeof m.validate === 'function'));
      return new _rsvp.default.Promise(function (resolve, reject) {
        m.validate().then(function () {
          model.get('isValid') ? resolve() : reject();
        }, reject);
      });
    }
  });
});