define("ember-table/components/-private/scroll-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vMFM3Oj2",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"scroll-indicator scroll-indicator__left\"],[15,5,[36,2]],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,3],[[[1,\"  \"],[10,0],[14,0,\"scroll-indicator scroll-indicator__right\"],[15,5,[36,4]],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,5],[[[1,\"  \"],[10,0],[14,0,\"scroll-indicator scroll-indicator__top\"],[15,5,[36,6]],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,7],[[[1,\"  \"],[10,0],[14,0,\"scroll-indicator scroll-indicator__bottom\"],[15,5,[36,8]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showLeft\",\"leftStyle\",\"showRight\",\"rightStyle\",\"showTop\",\"topStyle\",\"showBottom\",\"bottomStyle\"]]",
    "moduleName": "ember-table/components/-private/scroll-indicators/template.hbs",
    "isStrictMode": false
  });
});