define("ember-table/-private/utils/sort", ["exports", "@ember/utils", "@ember/object"], function (_exports, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compareValues = compareValues;
  _exports.mergeSort = mergeSort;
  _exports.sortMultiple = sortMultiple;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function merge(left, right, comparator) {
    var mergedArray = [];
    var leftIndex = 0;
    var rightIndex = 0;
    while (leftIndex < left.length && rightIndex < right.length) {
      var comparison = comparator(left[leftIndex], right[rightIndex]);
      if (comparison <= 0) {
        mergedArray.push(left[leftIndex]);
        leftIndex++;
      } else {
        mergedArray.push(right[rightIndex]);
        rightIndex++;
      }
    }
    if (leftIndex < left.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat(_toConsumableArray(left.slice(leftIndex))));
    }
    if (rightIndex < right.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat(_toConsumableArray(right.slice(rightIndex))));
    }
    return mergedArray;
  }

  /**
   * An implementation of the standard merge sort algorithm.
   *
   * This is necessary because we need a stable sorting algorithm that accepts
   * a general comparator. The built in sort function and Ember's sort functions
   * are not stable, and `_.sortBy` doesn't take a general comparator. Ideally
   * lodash would add a `_.sort` function whose API would mimic this function's.
   *
   * @param {Array} array The array to be sorted
   * @param {Comparator} comparator The comparator function to compare elements with.
   * @returns {Array} A sorted array
   */
  function mergeSort(array) {
    var comparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _utils.compare;
    if (array.length <= 1) {
      return array;
    }
    var middleIndex = Math.floor(array.length / 2);
    var leftArray = mergeSort(array.slice(0, middleIndex), comparator);
    var rightArray = mergeSort(array.slice(middleIndex), comparator);
    return merge(leftArray, rightArray, comparator);
  }
  function sortMultiple(itemA, itemB, sorts, compare, sortEmptyLast) {
    var compareValue;
    var _iterator = _createForOfIteratorHelper(sorts),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _step.value,
          valuePath = _step$value.valuePath,
          isAscending = _step$value.isAscending;
        var valueA = (0, _object.get)(itemA, valuePath);
        var valueB = (0, _object.get)(itemB, valuePath);

        // The option only influences the outcome of an ascending sort.
        if (sortEmptyLast) {
          sortEmptyLast = isAscending;
        }
        compareValue = isAscending ? compare(valueA, valueB, sortEmptyLast) : -compare(valueA, valueB, sortEmptyLast);
        if (compareValue !== 0) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return compareValue;
  }
  function isExactlyNaN(value) {
    return typeof value === 'number' && isNaN(value);
  }
  function isEmptyString(value) {
    return typeof value === 'string' && value === '';
  }
  function isEmpty(value) {
    return (0, _utils.isNone)(value) || isExactlyNaN(value) || isEmptyString(value);
  }
  function orderEmptyValues(itemA, itemB, sortEmptyLast) {
    var aIsEmpty = isEmpty(itemA);
    var bIsEmpty = isEmpty(itemB);
    var less = -1;
    var more = 1;
    if (sortEmptyLast) {
      less = 1;
      more = -1;
    }
    if (aIsEmpty && !bIsEmpty) {
      return less;
    } else if (bIsEmpty && !aIsEmpty) {
      return more;
    } else if ((0, _utils.isNone)(itemA) && isExactlyNaN(itemB)) {
      return less;
    } else if (isExactlyNaN(itemA) && (0, _utils.isNone)(itemB)) {
      return more;
    } else {
      return 0;
    }
  }
  function compareValues(itemA, itemB, sortEmptyLast) {
    if (isEmpty(itemA) || isEmpty(itemB)) {
      return orderEmptyValues(itemA, itemB, sortEmptyLast);
    }
    return (0, _utils.compare)(itemA, itemB);
  }
});