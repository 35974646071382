define("ember-macro-helpers/-build-computed", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCurriedComputed = buildCurriedComputed;
  _exports.default = _default;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function parseComputedArgs(args) {
    return {
      keys: args.slice(0, -1),
      callback: args[args.length - 1]
    };
  }
  function buildCallback(_ref) {
    var incomingCallback = _ref.incomingCallback,
      createArgs = _ref.createArgs;
    var newCallback;
    if (typeof incomingCallback === 'function') {
      newCallback = function newCallback(key) {
        return incomingCallback.apply(this, createArgs(this, key));
      };
    } else {
      newCallback = {};
      if (incomingCallback.get) {
        newCallback.get = function (key) {
          return incomingCallback.get.apply(this, createArgs(this, key));
        };
      }
      if (incomingCallback.set) {
        newCallback.set = function (key, value) {
          var _incomingCallback$set;
          return (_incomingCallback$set = incomingCallback.set).call.apply(_incomingCallback$set, [this, value].concat(_toConsumableArray(createArgs(this, key))));
        };
      }
    }
    return newCallback;
  }
  function _default(_ref2) {
    var collapseKeys = _ref2.collapseKeys,
      getValue = _ref2.getValue,
      flattenKeys = _ref2.flattenKeys,
      isLazy = _ref2.isLazy;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      var _parseComputedArgs = parseComputedArgs(args),
        keys = _parseComputedArgs.keys,
        incomingCallback = _parseComputedArgs.callback;
      var collapsedKeys = collapseKeys(keys);
      function createArgs(context, key) {
        var bundledKeys = collapsedKeys.map(function (macro) {
          return {
            context: context,
            macro: macro,
            key: key
          };
        });
        var values;
        if (isLazy) {
          values = bundledKeys.slice();
          values.splice(0, 0, getValue);
        } else {
          values = bundledKeys.map(getValue);
        }
        return values;
      }
      var newCallback = buildCallback({
        incomingCallback: incomingCallback,
        createArgs: createArgs
      });
      return _object.computed.apply(void 0, _toConsumableArray(flattenKeys(keys)).concat([newCallback]));
    };
  }
  function buildCurriedComputed(computed) {
    return function (callback) {
      return function () {
        return computed.apply(void 0, Array.prototype.slice.call(arguments).concat([callback]));
      };
    };
  }
});