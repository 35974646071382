define("ember-bootstrap/components/bs-accordion/item/body", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsCollapse @collapsed={{@collapsed}} class={{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-collapse"}} role="tabpanel">
    <div class="{{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-body"}} {{if (macroCondition (macroGetOwnConfig "isBS4")) "card-body"}} {{if (macroCondition (macroGetOwnConfig "isBS5")) "accordion-body"}} {{@class}}">
      {{yield}}
    </div>
  </BsCollapse>
  */
  {
    "id": "lgYIRclg",
    "block": "[[[8,[39,0],[[16,0,[27]],[24,\"role\",\"tabpanel\"]],[[\"@collapsed\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[[27],\" \",\"card-body\",\" \",[27],\" \",[30,2]]]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@collapsed\",\"@class\",\"&default\"],false,[\"bs-collapse\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-accordion/item/body.hbs",
    "isStrictMode": false
  });
  /**
   Component for an accordion item body.
  
   See [Components.Accordion](Components.Accordion.html) for examples.
  
   @class AccordionItemBody
   @namespace Components
   @extends Ember.Component
   @public
   */

  /**
   * @property collapsed
   * @type boolean
   * @public
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});