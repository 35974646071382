define("@zestia/ember-auto-focus/utils/focus", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = focus;
  function focus(element) {
    element.dataset.programmaticallyFocused = 'true';
    element.focus();
    (0, _runloop.next)(function () {
      return delete element.dataset.programmaticallyFocused;
    });
  }
});