define("ember-table/components/ember-th/sort-indicator/component", ["exports", "@ember/component", "ember-table/components/ember-th/sort-indicator/template", "@ember/object/computed"], function (_exports, _component, _template, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    The table header cell sort indicator component. This component renders the state of the sort on the column (ascending/descending/none).
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{columns}} as |h|>
        <h.row as |r|>
          <r.cell as |columnValue columnMeta|>
            {{columnValue.name}}
  
            <EmberTh::SortIndicator @columnMeta={{columnMeta}} />
          </r.cell>
        </h.row>
      </t.head>
  
      <t.body @rows={{rows}} />
    </EmberTable>
    ```
    @yield {object} columnMeta - The meta object associated with this column
    @class {{ember-th/sort-indicator}}
  */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    /**
      The API object passed in by the table header cell
      @argument columnMeta
      @required
      @type object
    */
    columnMeta: null,
    isSortable: (0, _computed.readOnly)('columnMeta.isSortable'),
    isSorted: (0, _computed.readOnly)('columnMeta.isSorted'),
    isSortedAsc: (0, _computed.readOnly)('columnMeta.isSortedAsc'),
    isMultiSorted: (0, _computed.readOnly)('columnMeta.isMultiSorted'),
    sortIndex: (0, _computed.readOnly)('columnMeta.sortIndex')
  });
});