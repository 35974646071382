define("ember-bootstrap/version", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VERSION = void 0;
  _exports.registerLibrary = registerLibrary;
  var VERSION = _exports.VERSION = "5.0.0-alpha.1";
  function registerLibrary() {
    _ember.default.libraries.register('Ember Bootstrap', VERSION);
  }
});